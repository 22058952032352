<template>
  <b-card-code>
    <router-link class="text-white" :to="'/projects/add-project'">
      <!-- v-if="this.$store.state.app.user.is_staff" -->
      <b-button
        type="button"
        variant="primary"
        class="btn ml-auto m-1"
        size="sm"
      >
        Add New
      </b-button>
    </router-link>
    <b-table
      responsive
      id="dataTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      hover
      style="min-height: 200px"
    >
      <template #cell(created_at)="data">
        {{ data.item.created_at | formatdate }}
      </template>
      <template #cell(actions)="data">
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <b-dropdown-item @click="editProject(data.item.project_id)"
            >Edit</b-dropdown-item
          >

          <b-dropdown-divider />
          <b-dropdown-item
            @click="gotoDelete(data.item.project_id, data.item.project_name)"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>

    <!-- <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :link-gen="linkGen"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="dataTable"
      use-router
    /> -->
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ projectName }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteItem()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BBadge,
  BFormSelect,
  BSidebar,
  BFormTextarea,
  BModal,
  BCardText,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BBadge,
    BFormSelect,
    BSidebar,
    BFormTextarea,
    BModal,
    BCardText,
  },
  data() {
    return {
      pagination_pos: "center",
      // organization: this.$store.state.app.org_id,
      organization: !this.$store.state.app.user.is_staff
        ? this.$store.state.app.user.profile.organization
        : this.$store.state.app.org_id,
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "project_id", label: "ID" },
        { key: "project_name", label: "Project Name" },
        { key: "cdr_id", label: "CDR ID" },
        { key: "start_date", label: "Start Date" },
        { key: "end_date", label: "End Date" },
        { key: "created_at", label: "Created At" },
        { key: "actions", label: "Actions" },
      ],
      name_filter: "",
      directVariables: [],
      assetVariables: [],
      vulnVariables: [],
      projectName: "",
      project_id: "",
      openDeleteModal: false,
    };
  },
  //   watch: {
  //     currentPage: function (newVal, oldVal) {
  //       this.load();
  //     },
  //   },
  created: function () {
    if (this.organization != null) {
      this.load();
    }
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm A");
      }
    },
  },
  methods: {
    // linkGen: function (pageNum) {
    //   return this.$router.currentRoute.path + "?page=" + pageNum;
    // },
    load: function (search = false) {
      let url =
        process.env.VUE_APP_BASEURL +
        "project/project/?org_id=" +
        this.organization;
      //   if (this.$route.query.page && !search) {
      //     this.currentPage = this.$route.query.page;
      //     url = url + "&page=" + this.currentPage;
      //   }
      //   if (search) url = url + "&page=1";

      //   if (this.name_filter) {
      //     if (search) url = url + "&title=" + this.name_filter;

      //   }

      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data);

        this.items = res.data;
        // this.rows = res.data.length;
        // this.total = Math.ceil(res.data.count / this.perPage);
      });
    },

    editProject(id) {
      this.$router.push({ name: "Edit Project", params: { id: id } });
    },
    gotoDelete(project_id, project_name) {
      this.openDeleteModal = true;
      this.project_id = project_id;
      this.projectName = project_name;
    },
    deleteItem() {
      // this.ProjectName = project_name;
      // if (confirm("Do you really want to delete this " + this.ProjectName + "?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "project/project/" +
          this.project_id +
          "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
      // }
    },
  },
};
</script>
<style scoped>
.b-sidebar.sidebar-lg {
  width: 50rem !important;
}

.w-50rem {
  width: 50rem !important;
}

::v-deep .dropdown-menu {
  max-height: 150px;
  overflow-y: auto;
}
</style>
